@import 'constants';
@import 'mixins/media-queries';
@import 'mixins/fonts_legacy';
@import 'mixins/theme';
@import 'mixins/utilities';
@import 'hide-breakpoints';
@import 'animations';
@import 'layout';
@import 'fontstyles';
@import 'components';

.vh {
	@include visually-hidden;
}

.absolute-cover {
	position: absolute;
	top: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	left: 0;
	right: 0;
}

.full_frame_slide {
	position: absolute;
	top: 0;
	bottom: 0;
	width: 100vw;
	height: 100vh;
	left: 0;
	right: 0;
	overflow: hidden;

	transition: all 1s ease-in-out;
}

.overflow_off {
	overflow: hidden;
}

.tippy-box {
	-webkit-font-smoothing: antialiased;
}

.static-tweet {
	margin: 1rem auto;
}

.static-tweet-header {
	display: grid;
	grid-template-columns: 36px auto 20px;
	column-gap: 10px;
	width: 100%;
}

.static-tweet-header-name {
	white-space: unset;
}

.grid {
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	gap: 30px;
}

.embed-youtube {
	margin-top: 30px;
	margin-bottom: 30px;
	width: 100%;
}

.youtube-player {
	max-width: 100%;
}

.root {
	color: #000;
}

.root p,
.root h2,
.root h3 {
	color: #000;
	line-height: 1.5;

	img {
		object-fit: contain;
		width: 100%;
	}

	.article-content img {
		height: auto !important;
	}
}

.article-content p {
	font-size: 1.2rem;
}

.article-main h1 {
	color: #000;
}

.article-main a {
	color: inherit;
	text-decoration: none;

	&:hover {
		text-decoration: none;
	}
}

.root a {
	text-decoration: none;

	&:hover {
		text-decoration: none;
	}
}

.root p {
	margin-bottom: 0;
}

.article-content img {
	height: auto !important;
}

@keyframes special-fade-in {
	from {
		opacity: 0;
		transform: translate3d(0, 15%, 0);
	}

	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}

.specialFadeIn {
	opacity: 0;
	animation-name: special-fade-in;
}

.megaloader {
	position: fixed;
	inset: 0;
	z-index: 999;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding-bottom: 150px;
	background: white;
	row-gap: 15px;

	> span {
		color: black;
		font-size: 18px;
		font-weight: 500;
		z-index: 999999;
		line-height: 1.4;
		text-align: center;
	}

	> span:last-of-type {
		color: gray;
	}
}

s::before,
s::after {
	clip-path: inset(100%);
	clip: rect(1px, 1px, 1px, 1px);
	height: 1px;
	overflow: hidden;
	position: absolute;
	white-space: nowrap;
	width: 1px;
}

s::before {
	content: ' [start of stricken text] ';
}

s::after {
	content: ' [end of stricken text] ';
}

.legacy_inner {
	position: relative;
	margin-right: 24px;
	margin-left: 24px;

	@include sm-up {
		margin-left: 38px;
		margin-right: 38px;
	}

	@include lg-up {
		margin-left: 76px;
		margin-right: 76px;
	}
}

.legacy_container {
	position: relative;
	@include xl-up {
		margin-left: auto;
		margin-right: auto;
	}

	&.fluid {
		max-width: none;
	}

	&.fluid-mobile {
		padding-left: 0;
		padding-right: 0;
	}
}

.legacy_box {
	display: block;
	box-sizing: border-box;
	margin: 0;
	min-width: 0;
	width: 100%;
	background-color: #000;
	color: #fff;
}
